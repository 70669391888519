import '../sass/index.scss';
import React from "react";

function Home() {
return (
    <div className="home">
      <h1 className="section-title">394it</h1>
      <p className="lead-text">Founded in 2023, 394it is an up and coming web design and hosting service provided by small business, for small business.</p>
      <h2 className="section-title">What we offer</h2>
      <p>
        We offer affordable, personalized websites and web applications with continued
        maintenance and monitoring. 
      </p>
      <p>
        We also offer one on one conversations with the people
        who will developing your website, so you get the most accurate information and estimates
        without all of layers of buracracy that often plague larger development companies.</p>
      
      <h2 className="section-title">Frequent Questions</h2>
      <div className="flex-columns">
        <div className="faq">
          <h3>What is 394?</h3>
          <p>394 IT is a small business dedicated to helping other small businesses build their online presence through web design and automation.</p>
          <h3>Why 394?</h3>
          <p>394 refers to our local zip codes in South Mississippi, including Forrest, Jones, Pearl River counties and the surrounding areas. </p>
          <h3>What if I am not in your local area?</h3>
          <p>While the 394 area is our namesake and focus clientele, we are more than happy to work with you, no matter your location.</p>
        </div>
        <div className="map">
          <a href="http://www.maphill.com/united-states/mississippi/394/maps/shaded-relief-map/cropped-outside/" title="Shaded Relief Map of ZIP Codes Starting with 394, cropped outside" target='_blank' rel="noreferrer">
            <img src="http://maps.maphill.com/united-states/mississippi/394/maps/shaded-relief-map/cropped-outside/free-shaded-relief-map-of-394-cropped-outside.jpg" width="400" height="491" alt="Shaded Relief Map of ZIP Codes Starting with 394, cropped outside"/>
          </a>
          <br/>
          <a href="http://www.maphill.com/united-states/mississippi/394/maps/shaded-relief-map/cropped-outside/" title="Shaded Relief Map of ZIP Codes Starting with 394, cropped outside" target='_blank' rel="noreferrer">
            Click here to explore the map
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
