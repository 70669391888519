import '../sass/index.scss';

function About() {
  return (
    <div className="App">
    </div>
  );
}

export default About;
