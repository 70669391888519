import '../sass/index.scss';

function Contact() {
  return (
    <div className="App">
    </div>
  );
}

export default Contact;
